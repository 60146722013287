<script setup lang="ts">
defineProps({
  hideActions: {
    type: Boolean,
    default: false,
  },
})

const isOpen = ref<boolean>(true)

const { cart } = useCart()
</script>

<template>
  <div v-if="cart && cart.address" class="pb-4 mb-4 shadow sm:rounded-lg px-4 py-2">
    <div class="flex items-center justify-between py-4">
      <h4 class="block text-xl font-bold text-primary-40">
        Endereço de cobrança
      </h4>

      <button
        v-if="!hideActions"
        aria-label="Botão para alternar entre aberto e fechado"
        type="button"
        @click="isOpen = !isOpen"
      >
        <Icon
          name="ic:baseline-arrow-drop-down"
          class="w-6 h-6 duration-300 text-primary-50"
          :class="isOpen ? 'rotate-180 duration-300' : ''"
        />
      </button>
    </div>

    <div class="relative">
      <div
        class="transition-all duration-500 ease-in-out overflow-hidden bg-white"
        :class="isOpen ? 'max-h-[100%] opacity-100' : 'max-h-0 opacity-0'"
      >
        <div class="flex items-center justify-between mb-1">
          <span class="block text-sm text-normal text-neutral-30">
            {{ cart.address.addressOne }}, {{ cart.address.number }} - {{ cart.address.addressTwo }}
          </span>
          <NuxtLink
            v-if="!hideActions"
            class="text-primary-40 font-semibold text-xs leading-4 cursor-pointer"
            to="/checkout/endereco-de-cobranca"
            no-prefetch
          >
            Alterar
          </NuxtLink>
        </div>

        <span class="block text-sm text-normal text-neutral-30 mb-1">
          {{ cart.address.district }} - {{ cart.address.city }} - {{ cart.address.city }}
        </span>
        <span class="block text-sm text-normal text-neutral-30">
          {{ $filters.zipCode(cart.address.zip) }}
        </span>
      </div>
    </div>
  </div>
</template>
